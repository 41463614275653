import getUrlWithoutQuery from './getUrlWithoutQuery';

const getImageUrlWithoutQuery = (urlString = '') => {
  const urlWithoutQuery = getUrlWithoutQuery(urlString);
  // As part of the Imgix functionality, our images are often accessed via an
  // alias on fiore.buzz, e.g. "https://fiore.buzz/i/joybird2.imgix.net/
  // an-image.png" instead of "https://joybird2.imgix.net/an-image.png". To
  // allow image URLs to be compared, this function can be used to remove the
  // alias.

  return urlWithoutQuery;
};

export default getImageUrlWithoutQuery;
